<template>
    <div style="display: flex;flex-direction: column;">
        <div  class="from-button"  >
            <el-button type="success"  @click="buttomBack">取消</el-button>
            <el-button type="danger" @click="buttomSave">保存</el-button>
        </div>
        <el-col :span="20">
            <el-form  label-width="80px" >
                <el-form-item label="分类">
                    <el-select v-model="imageTypeName"  @change="imageTypeChange" placeholder="请选择" style="float: left">
                        <el-option
                            v-for="item in imageTypeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="促销图">
                    <van-uploader v-model="fileList"  :after-read="afterRead" :max-count="1" style="float: left"  :preview-options="{ closeable: true }" @delete="delFileImage" />
                </el-form-item>
                <el-form-item label="关联商品">
                    <el-select v-model="goodName" placeholder="请选择" filterable  size="medium" style="float: left" @change="goodChange" >
                        <el-option
                            v-for="item in goodData"
                            :key="item.id"
                            :label="item.name"
                            :value="item.name"
                            style="height: 50px;"
                            >
                            <div style="float: left;height: 50px" >
                                <el-image
                                style="width: 50px; height: 50px"
                                :src="item.goodImages.length===0?'':item.goodImages[0].url"
                                :fit="fit">
                                </el-image>
                                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.name }}</span>
                            </div>

                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否展示">
                    <el-switch style="float: left;padding: 10px"
                               v-model="isShow"
                               active-color="#13ce66"
                               inactive-color="#ff4949"
                               active-text="是"
                               inactive-text="否"
                               :active-value =1
                               :inactive-value =0>
                    </el-switch>
                </el-form-item>
                <el-form-item label="促销文案">
                    <el-input v-model="remark"></el-input>
                </el-form-item>
            </el-form>
        </el-col >
    </div>
</template>

<script>
import {queryGoodList, saveImage, saveModel, uploadFiles} from "@/api";
import { v4 as uuidv4 } from 'uuid';
export default {
    name: "Promotion_EDIT",
    mounted() {
        //全部商品
        queryGoodList({ 'imageType': 0}).then(re=>{
            this.goodData=re.data.data.list;
        }).then(res=>{
            const type =this.$route.params.types;
            if (type){//新增

            }else{//修改
                const promotionData=this.$route.params;
                this.id=this.$route.params.id;
                this.imageTypeId=this.$route.params.type;
                this.imageTypeName=this.imageTypeList.find(x=>x.id == this.imageTypeId).name;
                let file={
                    "url":promotionData.url,
                }
                this.fileList.push(file)
                const str=this.goodData.find(x=>x.id==promotionData.goodId)
                console.log("str",str)
                if (str){
                    this.goodName=str.name;
                    this.goodId=str.id;
                }
                this.remark=this.$route.params.remark;
                this.isShow=this.$route.params.isShow;

            }
        })

    },
    data() {
        return {
            id:'',
            imageTypeName:'',
            imageTypeId:'',
            goodName:'',
            goodId:'',
            isShow:1,
            remark:'',
            imageTypeList:[
                {"id":2,"name":"banner"},
                {"id":3,"name":"主推"},
                {"id":4,"name":"热销"}
            ],
            fileList: [],
            goodData:[],
        };
    },
    methods: {
        buttomBack(){
            this.$router.go(-1)
        },
        buttomSave(){
            const promotionData={
                "id":this.id?this.id:uuidv4(),
                "isShow":this.isShow,
                "goodId":this.goodId,
                "type":this.imageTypeId,
                "url":this.fileList[0].url,
                "remark":this.remark
            }
            saveImage(promotionData).then(res=>{
                if (res.data.success){
                    this.$notify({
                        title: '成功',
                        message: '保存成功',
                        type: 'success'
                    });
                    setTimeout( ()=>{
                        this.$router.go(-1);
                    },1000);
                }else {
                    this.$notify({
                        title: '失败',
                        message: '保存失败',
                        type: 'error'
                    });
                }
            })
        },
        imageTypeChange(e){
            this.imageTypeName=e;
            this.imageTypeId=this.imageTypeList.find((x)=>x.name===e).id;
            console.log("imageTypeId:",this.imageTypeId)
        },
        //图片上传
        afterRead(file){
            if (file instanceof Array) {
                file.forEach((v) => {
                    v.status = "uploading";
                    v.message = "上传中...";
                    this.uploadImg(v);
                });
            } else {
                file.status = "uploading";
                file.message = "上传中...";
                this.uploadImg(file);
            }

        },
        //上传
        uploadImg(file) {
            let formData = new FormData();
            formData.append("files", file.file);
            uploadFiles(formData).then((res) => {
                if (res.data.success) {
                    file.status = "success";
                    file.message = "";
                    file.goodId=this.goodData.id?this.goodData.id:this.addUUId;
                    file.url = res.data.data.imageUrl;
                }else {
                    file.status = "failed";
                    file.message = "上传失败";
                }
            }) .catch((err)=>{
                // 如果图片上传失败，页面上图片则显示上传失败
                file.status='failed';
                file.message='上传失败';
            })
        },
        delFileImage(e){
            this.fileList = this.fileList.filter(i => i.url !== e.url);
        },
        goodChange(e){
            this.goodName=e;
            this.goodId=this.goodData.find((x)=>x.name===e).id;
            console.log("imageTypeId:",this.goodId)
        },

    },
}
</script>

<style scoped>
.from-button{
    display: flex;
    flex-direction: row-reverse;
    padding: 5px;
}
</style>
