<template>
    <div class="aside">
        <el-menu background-color="#545c64"
                 text-color="#fff"
                 active-text-color="#ffd04b"
                 default-active="1-4-1"  class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
            <h4>后台管理</h4>
            <el-menu-item  @click="clickMenu(item.url)"  style="text-align: left "  v-for="item in noChild" :key="item.name" :index="item.name">
                    <i :class="`el-icon-${item.icon}`"></i>
                    <span  slot="title">{{ item.lable }}</span>
            </el-menu-item>


        </el-menu>

    </div>
</template>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}
.el-menu{
  border-right: none;
    height: 100vh;
    h4{
        color: white;
        text-align: center;
    }
}
</style>

<script>

import {mapState} from "vuex";

export default {
    data() {
        return {
            menu:[
                {
                    path: '/',
                    name: '首页',
                    icon: 'house',
                    url: '/home',
                    lable: '首页'
                },
                {
                    path: '/',
                    name: '用户管理',
                    icon: 'user',
                    url: '/user',
                    lable: '用户管理'
                },
                {
                    path: '/',
                    name: '促销管理',
                    icon: 'star-off',
                    url: '/promotion',
                    lable: '促销管理'
                },
                {
                    path: '/',
                    name: '商品管理',
                    icon: 'sell',
                    url: '/good',
                    lable: '商品管理',
                },
                {
                    path: '/',
                    name: '分类管理',
                    icon: 'connection',
                    url: '/model',
                    lable: '分类管理',
                },
                {
                    path: '/',
                    name: '订单管理',
                    icon: 'truck',
                    url: '/order',
                    lable: '订单管理',
                },
                {
                    path: '/',
                    name: '设置管理',
                    icon: 'setting',
                    url: '/setting',
                    lable: '设置管理',
                },
            ]
        };
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        clickMenu(info){
            this.$router.push(info);
        },

    },
    mounted() {
        console.log(this.noChild)
    },
    computed:{
        ...mapState(['isCollapse']),
        noChild(){
           return  this.menu.filter(item=>!item.children)
        },
        hasChild(){
            return  this.menu.filter(item=>item.children)
        }
    }
}
</script>
