<template>
  <div >
      <div style="display: flex;justify-content: space-between;align-items: flex-start;">
        <el-tabs v-model="activeName"  @tab-click="handleTabs" style="margin-left: 10px;margin-bottom: -15px">
            <el-tab-pane label="banner" name="2" ></el-tab-pane>
            <el-tab-pane label="热销" name="4"></el-tab-pane>
            <el-tab-pane label="主推" name="3"></el-tab-pane>
        </el-tabs>
        <el-button  type="danger"    @click="addPromotion">新增</el-button>
      </div>
      <div v-show="tabs==2" >
          <el-table :data="promotionList" border fit="fit" style="width: 100%">
              <el-table-column  prop="url" label="展示图"  >
                  <template   v-slot="scope">
                      <el-image  style="width: 120px; height: 120px"  :src="scope.row.url"  :fit="fit"></el-image>
                  </template>
              </el-table-column>
              <el-table-column prop="name" label="对应商品名称" />
              <el-table-column prop="isShow" label="是否展示" />
              <el-table-column :fixed="right" label="操作">
                  <template v-slot="scope">
                      <el-button type="text" size="small" @click="handleClick(scope.row)">编辑</el-button>
                      <el-button type="text" size="small" @click="delClick(scope.row)">删除</el-button>
                  </template>
              </el-table-column>
          </el-table>
      </div>
      <div v-show="tabs==4">
          <el-table :data="promotionList" border fit="fit" style="width: 100%">
              <el-table-column  prop="url" label="展示图"  >
                  <template   v-slot="scope">
                      <el-image  style="width: 120px; height: 120px"  :src="scope.row.url"  :fit="fit"></el-image>
                  </template>
              </el-table-column>
              <el-table-column prop="name" label="对应商品名称" />
              <el-table-column prop="isShow" label="是否展示" />
              <el-table-column :fixed="right" label="操作">
                  <template v-slot="scope">
                      <el-button type="text" size="small" @click="handleClick(scope.row)">编辑</el-button>
                      <el-button type="text" size="small" @click="delClick(scope.row)">删除</el-button>
                  </template>
              </el-table-column>
          </el-table>
      </div>
      <div v-show="tabs==3">
          <el-table :data="promotionList" border fit="fit" style="width: 100%">
              <el-table-column  prop="url" label="展示图"  >
                  <template   v-slot="scope">
                      <el-image  style="width: 120px; height: 120px"  :src="scope.row.url"  :fit="fit"></el-image>
                  </template>
              </el-table-column>
              <el-table-column prop="name" label="对应商品名称" />
              <el-table-column prop="remark" label="促销文字" resizable :show-overflow-tooltip='true' />
              <el-table-column prop="isShow" label="是否展示" />
              <el-table-column :fixed="right" label="操作">
                  <template v-slot="scope">
                      <el-button type="text" size="small" @click="handleClick(scope.row)">编辑</el-button>
                      <el-button type="text" size="small" @click="delClick(scope.row)">删除</el-button>
                  </template>
              </el-table-column>
          </el-table>
      </div>
  </div>
</template>
<script >

import {delGoodImage, getGoodImageList} from "@/api";
import { mapMutations, mapState} from "vuex";
import Vue from "vue";
export default {
    mounted() {
        this.getGoodImageList();

    },
    computed:{
        ...mapState(['userUrl','userName','roleName','userId']),
    },
    data(){
        return{
            activeName: "2",
            tabs:'2',//选中标签页
            promotionList:[],

        }
    },
    methods:{
        ...mapMutations(['setUserUrl','setUserName','setUserId']),
        handleTabs(tab) {
            this.tabs=tab.name;
            this.getGoodImageList();
        },
        getGoodImageList(){
            getGoodImageList({"type":this.tabs}).then(res=>{
                console.log(res)
                if (res.data.success){
                    this.promotionList=res.data.data;
                }
            })
        },
        //删除
        delClick(row){
            delGoodImage(row.id).then(res=>{
                if (res.data.success){
                    this.$notify({
                        title: '成功',
                        message: '删除成功',
                        type: 'success'
                    });
                    //刷新
                    this.getGoodImageList();
                }else {
                    this.$notify({
                        title: '失败',
                        message: '删除失败',
                        type: 'error'
                    });
                }

            })

        },
        addPromotion(){
            this.$router.push({name:"promotionEdit",params:{types:"add"}});
        },
        handleClick(row){
            this.$router.push({name:"promotionEdit",params:row});
        },
    },

}

</script>
<style>
.table-remark{
    width: 100px;  /*(一定要加宽度）*/
    overflow:hidden; /*超出的文本隐藏*/
    text-overflow: ellipsis; /*溢出用省略号*/
    white-space: nowrap;  /*溢出不换行*/
    -webkit-line-clamp: 1;   /*控制显示的行数*/
}
</style>
