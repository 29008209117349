<template>
    <div style="display: flex;flex-direction: column;">
        <div  class="from-button"  >
            <el-button type="success"  @click="buttomBack">取消</el-button>
            <el-button type="danger" @click="buttomSave">保存</el-button>
        </div>
        <el-col :span="20">
            <el-form  label-width="80px" :model="modelData">
                <el-form-item label="分类名称">
                    <el-input v-model="modelData.name"></el-input>
                </el-form-item>
                <el-form-item label="分类排序">
                    <el-input v-model="modelData.rank"></el-input>
                </el-form-item>
                <el-form-item label="是否展示">
                    <el-switch style="float: left;padding: 10px"
                               v-model="modelData.isShow"
                               active-color="#13ce66"
                               inactive-color="#ff4949"
                               active-text="是"
                               inactive-text="否"
                               :active-value =1
                               :inactive-value =0>
                    </el-switch>
                </el-form-item>
            </el-form>
        </el-col >
    </div>
</template>

<script>
import {saveModel} from "@/api";
import { v4 as uuidv4 } from 'uuid';
export default {
    name: "Model_EDIT",
    mounted() {
        this.addUUId =uuidv4();
        const type =this.$route.params.type;
        if (type){//新增

        }else{//修改
            this.modelData=this.$route.params;
        }


    },
    data() {
        return {
            addUUId:'',
            modelData:{
                "id":uuidv4(),
                "name":'',
                "rank":'',
                "isShow":''
            }
        };
    },
    methods: {
        buttomBack(){
            this.$router.go(-1)
        },
        buttomSave(){
            saveModel(this.modelData).then(res=>{
                if (res.data.success){
                    this.$notify({
                        title: '成功',
                        message: '保存成功',
                        type: 'success'
                    });
                    setTimeout( ()=>{
                        this.$router.go(-1);
                    },1000);
                }else {
                    this.$notify({
                        title: '失败',
                        message: '保存失败',
                        type: 'error'
                    });
                }
            })
        }
    },
}
</script>

<style scoped>
.from-button{
    display: flex;
    flex-direction: row-reverse;
    padding: 5px;
}
</style>
