<template>
  <!-- Login -->
    <div id="login">
        <div id="login-form">
            <div class="el-link-div">
            <el-link  class="password" @click="loginPass" :underline="false">密码登录</el-link>
            <el-link  class="message"   @click="loginMessage"  :underline="false">短信登录</el-link>
            </div>
            <h1>登陆界面</h1>
            <van-form @submit="onSubmit" class="demo-ruleForm" >
                <div v-show="loginType==0">
                    <van-cell-group>
                        <van-field  v-model="ruleForm.name" name="name" label="手机号" type="tel"  placeholder="请输入手机号"    :rules="[{validator: nameCheck, message: '请输入正确手机号码'}]" />
                        <van-field v-model="ruleForm.password" name="password" label="密码" placeholder="请输入密码"   :rules="[{validator: passwordCheck, }]"/>
                    </van-cell-group>
                </div>
                <div v-show="loginType==1">
                    <!-- 输入手机号，调起手机号键盘 -->
                    <van-field v-model="ruleForm.name" type="tel" label="手机号"  name="name" placeholder="请输入手机号"  :rules="[{validator: nameCheck, message: '请输入正确手机号码'}]" />
                    <van-field  v-model="ruleForm.message"  center  label="短信验证码"  name="message" placeholder="请输入短信验证码"  :rules="[{validator: messageCheck, }]">
                    <template #button>
                        <van-button size="small" type="primary" :disabled="messageDisabled" @click="sendcode">{{ btntxt }}</van-button>
                    </template>
                    </van-field>
                </div>

                <div class="input-div">
                    <van-button  :disabled="bottomDisabled"  class="submit-form" type="primary" @click="submitForm" size="medium"><span>登录</span></van-button>
                </div>
            </van-form >
        </div>
        <div class="footer">
            <CommonFooter/>
        </div>

    </div>
</template>

<script>
import {sendSmsCode,loginBackground,login} from "@/api"
import CommonFooter from "@/components/CommonFooter.vue";
import {mapMutations} from "vuex";
export default {
    name: "Login",
    components: {CommonFooter},
    data () {
        return {
            ruleForm:{
                name: '',
                password: '',
                message:'',
            },
            messageDisabled:true,
            bottomDisabled:true,
            loginType:0 ,//1:验证码登录   0:密码登录
            time:60,
            btntxt:"发送验证码",
            ruleResult:{
                name:false,
                password: false,
                message:false,
            }
        }
    },
    methods: {
        ...mapMutations(['setUserUrl','setUserName','setUserId']),
        nameCheck(val) {
            let result=false;
            if (/^1[3456789]\d{9}$/.test(val)){
                result=true
                this.ruleResult.name=true;
                this.messageDisabled=false;
            }else {
                this.ruleResult.name=false;
                this.messageDisabled=true;
            }
            return result;
        },
        passwordCheck(val) {
            let result=false;
            if (val){
                result=true
                this.ruleResult.password=true;
            }else {
                this.ruleResult.password=false;
            }
            return result;
        },
        messageCheck(val) {
            let result=false;
            if (val){
                result=true
                this.ruleResult.message=true;
            }else {
                this.ruleResult.message=false;
            }
            return result;
        },
        onSubmit(values) {
            console.log('submit', values);
        },
        loginPass(){
            this.loginType=0;
        },
        loginMessage(){
            this.loginType=1;
        },
        sendcode(){
            this.time=60;
            this.timer();
            sendSmsCode(this.ruleForm.name);

        },
        timer() {
            if (this.time > 0) {
                this.disabled=true;
                this.time--;
                this.btntxt=this.time+"秒";
                setTimeout(this.timer, 1000);
            } else{
                this.time=0;
                this.btntxt="发送验证码";
                this.disabled=false;
            }
        },
        submitForm() {
            var  user={
                mobile:this.ruleForm.name,
                checkCode:this.ruleForm.message,
                passWord:this.ruleForm.password
            }

            if (this.loginType===0){//账号密码登录
                   login(user).then(res=>{
                   var success=res.data.success;
                   if (success){
                       var sessionId= res.data.data.sessionId
                       localStorage.setItem("Authorization",sessionId);
                       localStorage.setItem("userId",res.data.data.userId);
                       localStorage.setItem("userName",res.data.data.userName);
                       localStorage.setItem("userUrl",res.data.data.imageUrl);
                      //Cookie.set("lc",sessionId);
                        this.$router.push("/home")
                   }else {
                       console.log(res.data);
                       this.$alert('登录失败，请重新输入', '登录提示', {
                           confirmButtonText: '确定',
                       });
                   }
                })
            }else {//验证码登录
                loginBackground(user).then(res=>{
                    var success=res.data.success;
                    if (success){
                        var sessionId= res.data.data.sessionId
                        localStorage.setItem("Authorization",sessionId);
                        localStorage.setItem("userId",res.data.data.userId);
                        localStorage.setItem("userName",res.data.data.userName);
                        localStorage.setItem("userUrl",res.data.data.imageUrl);
                        this.$router.push("/home")
                    }else {
                        this.$alert('登录失败，请重新输入', '登录提示', {
                            confirmButtonText: '确定',
                        });
                    }

                })
            }

        },
    },
    computed: {

    },
    mounted() {

    },
    watch: {
        ruleResult:{
            handler(val, olVal){
                console.log("变化",val)
              if (this.loginType===0){
                  if (val.password===true && val.name===true){
                      this.bottomDisabled=false;
                  }else {
                      this.bottomDisabled=true;
                  }
              }else if (this.loginType===1){
                  if (val.message===true && val.name===true){
                      this.bottomDisabled=false;
                  }else {
                      this.bottomDisabled=true;
                  }
              }

            },
            deep: true
        }
    }
}
</script>

<style lang="less" scoped>
.el-link-div{
    display: flex;


}
.el-link{
  margin: 10px;
}
#login {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  // 背景图片样式
  background-image: url("../assets/login.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

#login-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48vw;
  height: 48vh;
  min-width: 300px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  background-color:white;
  border-radius: 15px;
  // 表单 box-shadow 样式 好看
  box-shadow: 0 15px 25px rgba(0, 0, 0, .5);

      h1 {
        width: 60%;
        margin: 30px auto  0;
        height:60px;
        color: #c1c1c1;
        text-align: center;

      }
      .submit-form{
        width: 100%;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        position:fixed;
        bottom:0;
        left: 0;
      }
}



.footer{
    display: flex;
    flex-direction: column;
    margin-top: 620px;
}

</style>
