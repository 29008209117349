import axios from "axios";
const http=axios.create({
    baseURL:"https://lcor.top/shop",
    //baseURL:'http://localhost:8080/shop',
    timeout:100000000,
    headers: {
        "Authorization": localStorage.getItem("Authorization"),

    }

})
axios.interceptors.request.use(function (config) {
    return config;
},function (error) {
    return Promise.reject(error);
    }
)
axios.interceptors.response.use(function (config) {
        return config;
    },function (error) {
        return Promise.reject(error);
    }
)
export default http;
