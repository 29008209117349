<template>
  <div class="home">
      <el-row>
          <el-col :span="6" >
              <div class="grid-content bg-purple">
                  <el-card class="box-card">
                      <div class="user-up">
                          <div class="user-l"><img :src="userUrl"/></div>
                          <div class="user-r">
                              <p class="user-name">{{userName}}</p>
                              <p class="role-name" >{{roleName}}</p>
                          </div>
                      </div>
                      <div class="user-down">
                          <el-table
                              :data="tableData"
                              height="480px"
                              style="width: 100%">
                              <el-table-column
                                  prop="name"
                                  label="姓名">
                              </el-table-column>
                              <el-table-column
                                  prop="rice"
                                  label="购买金额">
                              </el-table-column>
                              <el-table-column
                                  prop="arrive"
                                  label="是否送达">
                              </el-table-column>
                          </el-table>
                      </div>

                  </el-card>
              </div>
          </el-col>
          <el-col :span="18" >
              <div class="r-card">
                  <el-card :body-style="{display:'flex',padding:0}">
                      <i class="el-icon-circle-check" ></i>
                      <div>
                          <p>今日实际单量</p>
                          <p>1</p>
                      </div>
                  </el-card>
                  <el-card :body-style="{display:'flex',padding:0}">
                      <i class="el-icon-location-outline" ></i>
                      <div>
                          <p>已送达量</p>
                          <p>1</p>
                      </div>
                  </el-card>
                  <el-card :body-style="{display:'flex',padding:0}">
                      <i class="el-icon-circle-close" ></i>
                      <div>
                          <p>今日退单数量</p>
                          <p>1</p>
                      </div>
                  </el-card>
                  <el-card :body-style="{display:'flex',padding:0}">
                      <i class="el-icon-circle-check" ></i>
                      <div>
                          <p>今日实际金额</p>
                          <p>1</p>
                      </div>
                  </el-card>

                  <el-card :body-style="{display:'flex',padding:0}">
                      <i class="el-icon-location-outline"></i>
                      <div>
                          <p>未送达量</p>
                          <p>1</p>
                      </div>
                  </el-card>

                  <el-card :body-style="{display:'flex',padding:0}">
                      <i class="el-icon-circle-close"></i>
                      <div>
                          <p>今日退单金额</p>
                          <p>1</p>
                      </div>
                  </el-card>
              </div>

              <CommonEcharts :option="option" :style="{width:'100%' ,height:'500px'}"/>
          </el-col>
      </el-row>
  </div>

</template>

<script>
import {mapState} from "vuex";
import CommonEcharts from "@/components/CommonEcharts.vue";
import {getData} from "@/api";

export default {
    components: {CommonEcharts},

    computed:{
        ...mapState(['userUrl','userName','roleName']),

    },
    mounted() {
        getData({}).then(res=>{
            console.log(res)
        })

    },
    data(){
        return{
            tableData:[
                {"name":"lc1","rice":"1","arrive":"1"}
            ],
            //柱状图数据
            option:{
                title: {
                    text: "",
                },
                tooltip: {},
                legend: {
                    data: ["销量"],
                },
                xAxis: {
                    data: ["瓜果", "葡萄", "坚果", "零食", "饮料", "其他"],
                },
                yAxis: {},
                series: [
                    {
                        name: "销量",
                        type: "bar",
                        data: [5, 20, 36, 10, 10, 20],
                    },
                ],
            }
        }
    },
    methods:{

    },

}



</script>
<style lang="less" scoped>

.user-up{
    align-items: center;
    display: flex;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px  solid darkgrey;

}
.user-r{
    align-items: center;
    margin-left: 50px;
    .user-name{
        font-size: 25px;
    }
    .role-name{
        font-size: 10px;
    }
}
.user-l img{
    border-radius: 50%;
    width: 80px;
    height: 80px;

}

.r-card{
    display: flex;
    flex-wrap: wrap;
    .el-card{
        width: 32.3%;
        margin: 3px 3px;
        height: 80px;
        padding: 0;
        i{
            width: 80px;
            height: 80px;
            text-align: center;
            color: white;
            line-height: 80px;
            font-size: 20px;
            background-color: #42b983;

        }
        div{
            flex-direction: column;
            align-items: center;
            margin-left: 80px;
        }
    }
}


</style>
