const page={
    "pageNum":1,
    "pageSize":5
}
function  pageInfo(){
    const p=JSON.parse(JSON.stringify(this.page))
    return p
}

export default {
    page,
    pageInfo
};
