<template>
      <div id="main" style="width: 600px; height: 400px"></div>
</template>
<script>
export  default {
      props:{
            option:Object
      },
      methods:{
            drawChart() {
                  // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
                  let myChart = this.$echarts.init(document.getElementById("main"));
                  // 指定图表的配置项和数据
                  let option = this.option;
                  // 使用刚指定的配置项和数据显示图表。
                  myChart.setOption(option);
            },

      },
      mounted() {
            this.drawChart();
      }
}
</script>
