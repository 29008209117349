<template>
  <div class="head" >
      <div class="l_head">
          <el-button type="text"  @click="collapse"  icon="el-icon-menu" circle></el-button>
          <span class="text">首页</span>
      </div>
      <div class="r_head" >
          <el-dropdown size="small" type="primary" >
              <el-button   class="ima-button" >
                 <img  class="user-image" :src=userUrl>
              </el-button>
              <el-dropdown-menu   slot="dropdown">
                  <el-dropdown-item >个人中心</el-dropdown-item>
                  <el-dropdown-item  @click.native="loginOut">退出</el-dropdown-item>
              </el-dropdown-menu>
          </el-dropdown>

      </div>
  </div>
</template>
<script >
import {mapMutations, mapState} from "vuex";

    export default {
        computed:{
            ...mapState(['userUrl'])
        },
        methods:{
            ...mapMutations(['collapse']),
            loginOut(){
                console.log("login=======1")
                localStorage.setItem("Authorization",'');
                this.$router.push("/login")
                console.log("login=======2")
            }
        }
    }
</script>
<style lang="less">
.head{
    background-color: teal;
    height: 60px;
    justify-content: space-between;
    display: flex;
    align-items: center;


    .l_head{
        .el-button{
            color: dimgray;
        }
    }
    .r_head{
        padding: 0 20px;
        border: none;
        .el-button{
            border: none;
            background: none
        }
        .user-image{
            height: 40px;
            width: 40px;
            border-radius: 50%;
            border: none;

        }

    }
}


</style>
