<template>
    <div>
        <el-button style="float: right; "  type="primary"   @click="addModel" >新增</el-button>
        <el-table
            :data="tableData"
            border
            style="width: 100%">
            <el-table-column
                prop="name"
                label="分类名称"
            >
            </el-table-column>
            <el-table-column
                prop="rank"
                label="排名"
            >
            </el-table-column>
<!--            <el-table-column
                prop="isShow"
                label="是否展示"
            >
            </el-table-column>-->
            <el-table-column
                fixed="right"
                label="操作"
            >
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="handleClick(scope.row)"  :disabled="scope.row.name==='全部'">编辑</el-button>
                    <el-button type="text" size="small" @click="delClick(scope.row)"  :disabled="scope.row.name==='全部'">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script >

import {delModel, queryGoodModelList} from "@/api";
import { mapMutations, mapState} from "vuex";
export default {
    mounted() {
        this.queryGoodModelList();
    },
    computed:{
        ...mapState(['userUrl','userName','roleName','userId']),
    },
    data(){
        return{
            tableData:""
        }
    },
    methods:{
        ...mapMutations(['setUserUrl','setUserName','setUserId']),
        handleClick(row){
            this.$router.push({name:"modelEdit",params:row});
        },
        addModel(){
            this.$router.push({name:"modelEdit",params:{type:"add"}});
        },
        //删除
        delClick(row){
            console.log("delClick",row)
            delModel(row.id).then(res=>{
                if (res.data.success){
                    this.$notify({
                        title: '成功',
                        message: '删除成功',
                        type: 'success'
                    });
                    //刷新
                    this.queryGoodModelList();
                }else {
                    this.$notify({
                        title: '失败',
                        message: '删除失败',
                        type: 'error'
                    });
                }
            })

        },
        queryGoodModelList(){
           queryGoodModelList({}).then(res=>{
               console.log(res)
               this.tableData=res.data.data;

           })
       },
    },

}

</script>
