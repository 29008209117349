import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isCollapse: false,   //左侧菜单是否折叠
    userUrl:localStorage.getItem("userUrl"),
    userName:localStorage.getItem("userName"),
    roleName:"",
    userId:localStorage.getItem("userId"),
  },
  getters: {
  },
  mutations: {
    collapse(state){
      state.isCollapse=!state.isCollapse;
    },

  },
  actions: {
  },
  modules: {
  }
})
