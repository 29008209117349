<template>
  <div>
      <el-row class="tac">
          <el-col :span="3">
              <el-menu class="el-menu-vertical-demo"  v-for="item in modelData" :default-active="modelData[0].id">
                  <el-menu-item  :index="item.id"   @click="clickModel(item.id)">
                      <span slot="title" >{{ item.name }}</span>
                  </el-menu-item>
              </el-menu>
          </el-col>
          <el-col :span="21">
              <el-button style="float: right" type="danger" @click="addGood">新增</el-button>
              <el-table
                  :data="goodData"
                  border

                  fit="fit"
                  style="width: 100%">
                  <el-table-column  prop="goodImages" label="主图"  >
                      <template   v-slot="scope">
                          <el-image  style="width: 120px; height: 120px"  :src="scope.row.goodImages.find((x)=>x.type===0)?scope.row.goodImages[0].url:''"  :fit="fit"></el-image>
                      </template>
                  </el-table-column>
                  <el-table-column prop="name" label="名称" />
                  <el-table-column prop="title" label="规格" :show-overflow-tooltip='true' />
                  <el-table-column prop="price" label="价格" />
                  <el-table-column prop="remark" label="介绍文字" resizable :show-overflow-tooltip='true' />
<!--                  <el-table-column prop="isShow" label="是否展示" />-->
                  <el-table-column :fixed="right" label="操作"  >
                      <template v-slot="scope">
                          <el-button type="text" size="small" @click="handleClick(scope.row)">编辑</el-button>
                          <el-button type="text" size="small" @click="delClick(scope.row)">删除</el-button>
                      </template>
                  </el-table-column>
              </el-table>
          </el-col>
      </el-row>
      <div>
          <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="this.pagination.pageNum"
              layout="total, prev, pager, next"
              :page-count="this.pagination.pages"
              :page-size="this.pages.pageSize"
              :total="this.pagination.total">
          </el-pagination>
      </div>
  </div>
</template>
<script >

import {delGood, queryGoodList, queryGoodModelList} from "@/api";
import { mapMutations, mapState} from "vuex";
import Vue from "vue";
export default {
    mounted() {
        this.pages=this.commons.pageInfo();
        //Vue.set(this.pages, 'imageType', 0)
        queryGoodModelList({}).then(res=>{
            this.modelData=res.data.data;
        })
        queryGoodList(this.pages).then(re=>{
            this.goodData=re.data.data.list;
            console.log("goodData", re.data.data)
            this.pagination={
                //总记录数
                total:re.data.data.total,
                //总页数
                pages:re.data.data.pages,
                //当前页 pageNum
                pageNum:re.data.data.pageNum,

            }
        })


    },
    computed:{
        ...mapState(['userUrl','userName','roleName','userId']),
    },
    data(){
        return{
            modelData:"",
            goodData:'',
            pages:'',
            pagination:'',
            modelId:'' //分类Id
        }
    },
    methods:{
        ...mapMutations(['setUserUrl','setUserName','setUserId']),
        clickMenu(info){
            this.$router.push(info);
        },
        handleCurrentChange(e){
            this.pages.pageNum=e;
            this.pagination.pageNum=e;
            queryGoodList(this.pages).then(re=>{
                this.goodData=re.data.data.list;
                console.log("goodData", re.data.data)

            })
        },
        clickModel(id){
            this.pages=this.commons.pageInfo();
            console.log("ididididid",id!=1)
            if(id!=1){
                this.modelId=id;
            }else {
                this.modelId='';
            }
            Vue.set(this.pages, 'modelId', this.modelId)
            console.log("this.pages",this.pages)
            this.queryGoodList();
        },
        handleClick(row){
            this.$router.push({name:"goodEdit",params:row});

        },
        //删除
        delClick(row){
            delGood(row.id).then(res=>{
                console.log("delGood")
                if (res.data.success){
                    this.$notify({
                        title: '成功',
                        message: '删除成功',
                        type: 'success'
                    });
                    //刷新
                    this.queryGoodList();
                }else {
                    this.$notify({
                        title: '失败',
                        message: '删除失败',
                        type: 'error'
                    });
                }

            })

        },
        addGood(){
            this.$router.push({name:"goodEdit",params:{type:"add"}});
        },
        queryGoodList(){
            queryGoodList(this.pages).then(re=>{
                this.goodData=re.data.data.list;
                console.log("goodData====", re.data.data)
                this.pagination={
                    //总记录数
                    total:re.data.data.total,
                    //总页数
                    pages:re.data.data.pages,
                    //当前页 pageNum
                    pageNum:re.data.data.pageNum,
                }
            })
        }
    },

}

</script>
<style>
.table-remark{
    width: 100px;  /*(一定要加宽度）*/
    overflow:hidden; /*超出的文本隐藏*/
    text-overflow: ellipsis; /*溢出用省略号*/
    white-space: nowrap;  /*溢出不换行*/
    -webkit-line-clamp: 1;   /*控制显示的行数*/
}
</style>
