import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/Home.vue'
import main from "@/views/Main.vue";
import good from "@/views/Good.vue";
import model from "@/views/Model.vue";
import order from "@/views/Order.vue";
import setting from "@/views/Setting.vue";
import user from "@/views/User.vue";
import Login from "@/views/Login.vue";
import model_EDIT from "@/views/Good_EDIT.vue";
import Good_EDIT from "@/views/Good_EDIT.vue";
import Model_EDIT from "@/views/Model_EDIT.vue";
import promotion from "@/views/Promotion.vue";
import Promotion_EDIT from "@/views/Promotion_EDIT.vue";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: main,
    redirect: '/home',
    children:[
      {
        path: 'home',
        name: 'home',
        component: home
      },
      {
        path: 'good',
        name: 'good',
        component: good
      },
      {
        path: 'goodEdit',
        name: 'goodEdit',
        component: Good_EDIT
      },
      {
        path: 'model',
        name: 'model',
        component: model,

      },
      {
        path: 'modelEdit',
        name: 'modelEdit',
        component: Model_EDIT,

      },
      {
        path: 'order',
        name: 'order',
        component: order
      },
      {
        path: 'setting',
        name: 'setting',
        component: setting
      },
      {
        path: 'user',
        name: 'user',
        component: user
      },
      {
        path: 'promotion',
        name: 'promotion',
        component: promotion
      },
      {
        path: 'promotionEdit',
        name: 'promotionEdit',
        component: Promotion_EDIT
      }

    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  }

]

const router = new VueRouter({
  mode: 'history',
  routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)

}

export default router
