import http from "@/utils/https";

export const getData=(info)=>{
   return http.post("/sys/user/queryList",info);
}

export const sendSmsCode=(info)=>{
   console.log("........................"+info)
   return http.get("/sys/login/sendSmsCode/"+info);
}

//后台专用登录接口
export const loginBackground=(user)=>{
   return http.post("/sys/loginBackground",user);
}
//后台专用登录接口
export const login=(user)=>{
   return http.post("/sys/login",user);
}
//查询分类
export const queryGoodModelList=(info)=>{
   return http.post("/good/queryGoodModelList",info);
}

//查询商品
export const queryGoodList=(info)=>{
   return http.post("/good/queryGoodList",info);
}
//图片上传
export const uploadFiles=(info)=>{
   return http.post("/sys/user/uploadFiles",info);
}
export const saveGood=(info)=>{
   return http.post("/good/saveGood",info);
}
export const delGood=(info)=>{
   return http.post("/good/delGood",info,{
      headers:{ 'Content-Type': 'text/plain'}
   });
}
export const saveModel=(info)=>{
   return http.post("/good/saveModel",info);
}
export const delModel=(info)=>{
   return http.post("/good/delModel",info,{
      headers:{ 'Content-Type': 'text/plain'}
   });
}
export const getGoodImageList=(info)=>{
   return http.post("/good/getGoodImageList",info);
}
export const delGoodImage=(info)=>{
   return http.post("/good/delGoodImage",info,{
      headers:{ 'Content-Type': 'text/plain'}
   });
}
export const saveImage=(info)=>{
   return http.post("/good/saveImage",info);
}
