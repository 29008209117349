<template>
    <div style="display: flex;flex-direction: column;">
        <div  class="from-button"  >
            <el-button type="success"  @click="buttomBack">取消</el-button>
            <el-button type="danger" @click="buttomSave">保存</el-button>
        </div>
        <el-col :span="20">
            <el-form  label-width="80px" :model="goodData">
                <el-form-item label="分类">
                    <el-select v-model="modelName"  @change="modelChange" placeholder="请选择" style="float: left">
                        <el-option
                            v-for="item in modelData"
                            :key="item.id"
                            :label="item.name"
                            :value="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="主图">
                    <van-uploader v-model="fileList"  :after-read="afterRead" :max-count="1" style="float: left"  :preview-options="{ closeable: true }" @delete="delFileImage" />
                </el-form-item>
                <el-form-item label="名称">
                    <el-input v-model="goodData.name"></el-input>
                </el-form-item>
                <el-form-item label="规格">
                    <el-input v-model="goodData.title"></el-input>
                </el-form-item>
                <el-form-item label="价格">
                    <el-input v-model="goodData.price"></el-input>
                </el-form-item>
                <el-form-item label="介绍文字">
                    <el-input v-model="goodData.remark"></el-input>
                </el-form-item>
                <el-form-item label="介绍图片">
                    <van-uploader v-model="multipleList" max-size="2000*1024"   :after-read="afterRead" multiple :max-count="9" style="float: left"  :preview-options="{ closeable: true }" @delete="delMutImage" />
                </el-form-item>
                <el-form-item label="是否展示">
                    <el-switch style="float: left;padding: 10px"
                               v-model="goodData.isShow"
                               active-color="#13ce66"
                               inactive-color="#ff4949"
                               active-text="是"
                               inactive-text="否"
                               :active-value =1
                               :inactive-value =0>
                    </el-switch>
                </el-form-item>
            </el-form>
        </el-col >
    </div>
</template>

<script>
import {queryGoodModelList, saveGood, uploadFiles} from "@/api";
import { v4 as uuidv4 } from 'uuid';
export default {
    name: "Model_EDIT",
    mounted() {
        this.addUUId =uuidv4();
        const type =this.$route.params.type;
        console.log("type:",type);
        if (type){//新增
            //分类查询
            queryGoodModelList({}).then(res=>{
                this.modelData=res.data.data;
            })
        }else{//修改
            this.goodData=this.$route.params
            console.log("this.goodData",this.goodData)
            const fileData=this.goodData.goodImages.find((x)=>x.type===0)
            let file={
                "url":fileData.url,
                "id":fileData.id,
                "type":fileData.type,
                "goodId":fileData.goodId
            }
            this.fileList.push(file)
            let mut=this.goodData.goodImages.filter((x)=>x.type===1)
            mut.forEach(x=>{
                let file={
                    "url":x.url,
                    "id":x.id,
                    "type":x.type,
                    "goodId":x.goodId
                }
                this.multipleList.push(file);
            })
            //分类查询
            queryGoodModelList({}).then(res=>{
                this.modelData=res.data.data;
                this.modelData.forEach(x=>{
                    const modelId=this.goodData.modelId;
                    console.log("modelId",modelId)
                    if(modelId===x.id){
                        this.modelName=x.name;
                        this.modelId=modelId;
                        return true;
                    }
                })
            })

        }


    },
    data() {
        return {
            addUUId:'',
            fileList: [],
            goodData:{
                "name":'',
                "title":'',
                "price":'',
                "remark":'',
                "isShow":1,

            },
            multipleList:[],
            modelData:'',
            modelName:'',
            modelId:'',
        };
    },
    methods: {
        //图片上传
        afterRead(file){
            if (file instanceof Array) {
                file.forEach((v) => {
                    v.status = "uploading";
                    v.message = "上传中...";
                    this.uploadImg(v);
                });
            } else {
                file.status = "uploading";
                file.message = "上传中...";
                this.uploadImg(file);
            }
            console.log("fileList",this.fileList)
        },
        //上传
        uploadImg(file) {
            let formData = new FormData();
            formData.append("files", file.file);
            uploadFiles(formData).then((res) => {
                if (res.data.success) {
                    file.status = "success";
                    file.message = "";
                    file.goodId=this.goodData.id?this.goodData.id:this.addUUId;
                    file.url = res.data.data.imageUrl;
                }else {
                    file.status = "failed";
                    file.message = "上传失败";
                }
            }) .catch((err)=>{
                // 如果图片上传失败，页面上图片则显示上传失败
                file.status='failed';
                file.message='上传失败';
            })
        },
        delFileImage(e){
            this.fileList = this.fileList.filter(i => i.url !== e.url);
        },
        delMutImage(e){
            this.multipleList = this.multipleList.filter(i => i.url !== e.url);
        },
        //分类选择
        modelChange(e){
            this.modelName=e;
            this.modelId=this.modelData.find((x)=>x.name===e).id;
            console.log("modelId:",this.modelId)
        },
        buttomBack(){
            this.$router.go(-1)
        },
        buttomSave(){
            this.fileList.forEach((x)=>{
                x.type=0
            })
            this.multipleList.forEach((x)=>{
                x.type=1
            })
            const info ={
                "id":this.goodData.id?this.goodData.id:this.addUUId,
                "modelId":this.modelId,
                "name":this.goodData.name,
                "price":this.goodData.price,
                "title":this.goodData.title,
                "isShow":this.goodData.isShow,
                "remark":this.goodData.remark,
                "goodImages":[
                    ...this.fileList,...this.multipleList

                ]
            }
            console.log(info)
            saveGood(info).then(res=>{
                if (res.data.success){
                    this.$notify({
                        title: '成功',
                        message: '保存成功',
                        type: 'success'
                    });
                    setTimeout( ()=>{
                        this.$router.go(-1);
                    },1000);
                    console.log("saveGood",res)
                }else {
                    this.$notify({
                        title: '失败',
                        message: '保存失败',
                        type: 'error'
                    });
                }

            })
        }
    },
}
</script>

<style scoped>
.from-button{
    display: flex;
    flex-direction: row-reverse;
    padding: 5px;
}
</style>
